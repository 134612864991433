<template lang="pug">
  v-container
    v-row
      v-col(cols="12")
        v-card
          vd-client-application-table
      //- v-col(lg="7" cols="12")
      //-   v-card
      //-     v-container
      //-       router-view 
</template>
<script>
export default {
  name: 'VdClientApplications',
  components: {
    vdClientApplicationTable: () => import('./Table'),
  },
}
</script>